import { type NextPage } from "next";
import Head from "next/head";
import Parallax0 from "./parralax0.svg";
import Parallax1 from "./parralax1.svg";
import Parallax2 from "./parralax2.svg";
import { ImgDialog } from "~/ImgDialog/ImgDialog";
import { useState } from "react";
import { IconExternalLink } from "@tabler/icons-react";
// import Link from "next/link";

const Home: NextPage = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Head>
        <title>Bori és Andris</title>
        <meta name="description" content="Bori és Andris - 2023. június 24." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0,minimum-scale=1.0" />

        <link rel="icon" href="/icon.svg" />
        <link rel="apple-touch-icon" href="/icon-180.png" />

        <meta property="og:image" content="/icon-512.png" />
        <meta property="og:title" content="Bori és Andris esküvője - 2023. június 24." />

        <link href="https://fonts.cdnfonts.com/css/la-paloma" rel="stylesheet" />
        <link href="https://fonts.cdnfonts.com/css/bellefair" rel="stylesheet" />
        <link href="https://fonts.cdnfonts.com/css/caveat" rel="stylesheet" />
      </Head>

      <div
        className="parallax-container [--cardHeight:60vh] sm:[--cardHeight:70vh] md:[--cardHeight:80vh] lg:[--cardHeight:90vh] xl:[--cardHeight:100vh]"
        style={{ perspective: isOpen ? "none" : "1px" }}
      >
        <div className="parallax-card z-[-1] [--zDistance:0.7]">
          <ParallaxNagyhegy />
          <div className="absolute inset-x-0 top-[calc(var(--cardHeight)-2px)] h-[20vh] w-[100vw] bg-[#D8E1E2]" />
        </div>
        <div className="parallax-card z-[-1] [--zDistance:0.3]">
          <ParallaxBalaton />
          <div className="absolute inset-x-0 top-[calc(var(--cardHeight)-2px)] h-[20vh] w-[100vw] bg-[#627981]" />
        </div>
        <div className="parallax-card z-[-1] [--zDistance:0.0]">
          <ParallaxBoriek />
        </div>
        <div className="parallax-card z-[-1] [--zDistance:0.4]">
          <ParallaxDatum />
        </div>
        <div className="parallax-card z-[-1] [--zDistance:0.5]">
          <ParallaxBoriAndris />
        </div>

        <div className="relative mt-[calc(var(--cardHeight)-2px)] min-h-[100vh] bg-[#202C41]">
          <div className="mx-auto max-w-[1000px] px-10 py-20 text-white">
            <div>
              <div className="text-3xl">Képek az esküvőről</div>
              <a
                href="https://drive.google.com/drive/folders/1V7eBXQlMzC8eGg1KyCXNj7DjhuAnGo4F"
                target="_blank"
                className="-ml-2 inline-block break-all rounded border border-transparent px-2 py-1 text-sm text-xl opacity-70 hover:border-white/50 active:bg-white/20"
              >
                https://drive.google.com/drive/folders/1V7eBXQlMzC8eGg1KyCXNj7DjhuAnGo4F
                <IconExternalLink className="mb-[3px] ml-2 inline-block h-4 w-4" />
              </a>
            </div>

            {/*<hr className="-mx-8 my-24 h-px border-0 bg-white/20" />*/}

            {/*<div className="text-3xl">Képek rólunk</div>*/}
          </div>

          {/*<ImgDialog
            isOpen={isOpen}
            setOpen={setOpen}
            images={[
              { src: "/images/IMG_1329.jpeg", className: "object-contain" },
              { src: "/images/IMG_8304.JPG", className: "object-contain" },
              { src: "/images/IMG_1001.JPG", className: "object-contain" },
              { src: "/images/IMG_8362.JPG", className: "object-contain" },
              { src: "/images/IMG_1351.jpeg", className: "object-contain" },
              { src: "/images/IMG_2989.jpeg", className: "object-contain" },
              { src: "/images/IMG_3724.jpeg", className: "object-contain" },
              { src: "/images/IMG_4165.JPG", className: "object-contain" },
              { src: "/images/IMG_4216.jpeg", className: "object-contain" },
              { src: "/images/IMG_4647.JPG", className: "object-contain" },
              { src: "/images/IMG_6140.jpeg", className: "object-contain" },
              { src: "/images/IMG_6258.jpeg", className: "object-contain" },
              { src: "/images/IMG_8308.JPG", className: "object-contain" },
              { src: "/images/IMG_6378.JPG", className: "object-contain" },
              { src: "/images/IMG_0037.jpeg", className: "object-contain" },
              { src: "/images/IMG_9626.JPG", className: "object-contain" },
              { src: "/images/IMG_9675.JPG", className: "object-contain" },
              // { src: "/images/IMG_9801.JPG", className: "object-contain" },
              { src: "/images/IMG_20220213_124553.jpeg", className: "object-contain" },
              { src: "/images/IMG_20221218_111542_1.jpg", className: "object-contain" },
              { src: "/images/P_20221218_164412.jpg", className: "object-contain" },
              { src: "/images/PXL_20221218_100830636.jpg", className: "object-contain" },
              // { src: "/images/IMG_0380.JPG", className: "object-contain" },
            ]}
          />*/}

          <div className="h-[200px]"></div>

          <div className="mx-5 flex justify-center border-t border-white/10 py-4 pb-40 font-caveat text-white/50 lg:text-2xl">
            <div>
              - Tudsz játszani? - Tudok. - És szeretsz is? - És szeretlek is. <br />
              <div className="-mr-2 text-right text-xs lg:text-xl">Fodor Ákos</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

function ParallaxBoriAndris() {
  return (
    <h1 className="absolute bottom-[60%] left-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap font-lapaloma text-[14pt] text-[#213D59] sm:text-[18pt] md:text-[24pt] lg:bottom-[50%]  lg:text-[30pt] xl:text-[40pt]">
      Bori és Andris
    </h1>
  );
}

function ParallaxDatum() {
  return (
    <h2 className="absolute bottom-[50%] left-1/2  -translate-x-1/2 -translate-y-1/2 whitespace-nowrap font-bellefair text-[12pt] uppercase text-[#627981] sm:text-[16pt] md:text-[20pt] lg:bottom-[40%] lg:text-[26pt] xl:text-[30pt]">
      2023. június 24.
    </h2>
  );
}

function ParallaxNagyhegy() {
  return (
    <>
      <Parallax2 className="absolute inset-x-0 bottom-0 xl:bottom-[-20vh]" />
    </>
  );
}

function ParallaxBalaton() {
  return (
    <>
      <Parallax1
        className="absolute inset-x-0 bottom-0 xl:bottom-[-8vh]"
        style={{ filter: `drop-shadow(0px 0px 60px rgba(255, 255, 255, 0.5))` }}
      />
    </>
  );
}

function ParallaxBoriek() {
  return (
    <>
      <Parallax0
        className="absolute inset-x-0 bottom-0 xl:bottom-[-3vh]"
        style={{ filter: `drop-shadow(0px 0px 60px rgba(255, 255, 255, 0.5))` }}
      />
      {/*<div className="absolute inset-x-0 top-[var(--parallaxInnerHeight)] h-[20vh] w-[100vw] bg-[#202C41]" />*/}
    </>
  );
}
